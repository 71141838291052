import { useContext, useState } from 'react';
import Check from '../images/check.png';
import { acctValidatorInputs } from '../utils/formInput-values';
import { reasonsForVisit } from '../utils/genders-languages';
import { validationSchema1 } from '../utils/validationSchema';
import { LeadContext, leadInitialValue, ILead } from '../store/LeadContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { postTicket } from '../services/lead-api';
import ReactGA from 'react-ga';
import { Loader } from './loader';

export const AcctValidatorForm = () => {
  const navigate = useNavigate();
  const leadContext = useContext(LeadContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const [searchParams] = useSearchParams();

  const sendData = async (values: Partial<ILead>) => {
    try {
      values.location = searchParams.get('location') || 'IDG-NY-01';
      const ticket = await postTicket(values);
      values.ticketNumber = ticket.ticketNumber;

      if (Object.keys(ticket).includes('error')) {
        leadContext?.dispatch(values);
        sessionStorage.setItem('formData', JSON.stringify(values));
        setShowSpinner(false);
        navigate(`/new-ticket-form?location=${values.location}`);
      } else {
        sessionStorage.setItem('ticketNumber', ticket.numberVisit);
        leadContext?.dispatch(values);
        setShowSpinner(false);
        navigate('/ticket-registered-confirmation');
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  const formik = useFormik<Partial<ILead>>({
    initialValues: leadInitialValue,
    validationSchema: validationSchema1,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      ReactGA.event({
        category: 'Account & Lead Validation Check',
        action: 'Continue Button Clicked',
      });

      try {
        setShowSpinner(true);
        await sendData(values);
      } catch (error) {
        console.log('error:', error);
      }
    },
  });

  const { errors, handleSubmit, handleChange, touched, getFieldProps, isSubmitting } = formik;

  const reasonsListRender = reasonsForVisit.map((option) => {
    return (
      <option key={option.value} disabled={option.disabled} value={option.value}>
        {option.label}
      </option>
    );
  });

  const handleDoYouHaveTlc = (value: boolean) => {
    formik.setFieldValue('doYouHaveTlc', value)
  }

  const handleOnChange = (value: any, field: string) => {
    formik.setFieldValue(field, value.target.value)
  }

  return (
    <>
      {showSpinner && <Loader />}
      <form className="form" onSubmit={handleSubmit}>
        {acctValidatorInputs.map((acctValidatorInput) => {
          const { label, name, placeholder, type } = acctValidatorInput;
          return (
            <div className="form-field" key={name} id={`${name}-container`}>
              <label htmlFor={name} id={`${name}-label`}>
                <div className="inputLabels">
                  <p className="field" id={`acct-validator-${name}`}>
                    {label}
                  </p>
                  {touched[name] && errors[name] && <span className="errorMessage">{errors[name]}</span>}
                </div>
                <input id={`${name}-validator-input`} placeholder={placeholder} type={type} {...getFieldProps(name)} onChange={(e) => handleChange(e)} />
              </label>
            </div>
          );
        })}

        <div className="form-field">
          <div className="inputLabels">
            <p className="field" id="acct-validator-reasons">
              Do you have a TLC Number
            </p>
            {touched.doYouHaveTlc && errors.doYouHaveTlc ? <span className="errorMessage">{errors.doYouHaveTlc}</span> : <></>}
          </div>
          <label htmlFor="doYouHaveTlc">
            <div className="checkboxContainer">
              <div className="checkboxComponent" onClick={() => handleDoYouHaveTlc(true)}>
                {formik.values.doYouHaveTlc && <img src={Check} alt="ticket icon for confirmed ticket screen" />}
                Yes
              </div>
              <div className="checkboxComponent" onClick={() => handleDoYouHaveTlc(false)}>
                {typeof formik.values.doYouHaveTlc !== 'undefined' && !formik.values.doYouHaveTlc && <img src={Check} alt="ticket icon for confirmed ticket screen" />}
                No
              </div>
            </div>
            {typeof formik.values.doYouHaveTlc !== 'undefined' && <div className="form-field" key={formik.values.doYouHaveTlc ? 'TLC Number' : 'License Number'} id={`${formik.values.doYouHaveTlc ? 'TLC Number' : 'License Number'}-container`}>
              <label htmlFor={formik.values.doYouHaveTlc ? 'TLC Number' : 'Drivers License Number'} id={`${formik.values.doYouHaveTlc ? 'tlcNumber' : 'driversLicenseNumber'}-label`}>
                <div className="inputLabels">
                  <p className="field" id="acct-validator-reasons">{formik.values.doYouHaveTlc ? 'TLC Number' : 'Drivers License Number'}</p>
                  {touched[formik.values.doYouHaveTlc ? 'tlcNumber' : 'driversLicenseNumber'] && errors[formik.values.doYouHaveTlc ? 'tlcNumber' : 'driversLicenseNumber'] && <span className="errorMessage">{errors[formik.values.doYouHaveTlc ? 'tlcNumber' : 'driversLicenseNumber']}</span>}
                </div>
                <input 
                  maxLength={formik.values.doYouHaveTlc ? 7 : 20}
                  {...getFieldProps(formik.values.doYouHaveTlc ? 'tlcNumber' : 'driversLicenseNumber')} 
                  id={formik.values.doYouHaveTlc ? 'tlcNumber' : 'driversLicenseNumber'} 
                  placeholder={formik.values.doYouHaveTlc ? 'Enter TLC Number' : 'Enter Drivers License Number'} 
                  type="text" 
                  onChange={(e) => handleOnChange(e, formik.values.doYouHaveTlc ? 'tlcNumber' : 'driversLicenseNumber')} />
              </label>
            </div>}
          </label>
        </div>


        <div className="form-field">
          <div className="inputLabels">
            <p className="field" id="acct-validator-reasons">
              Reason For Visit
            </p>
            {touched.reasonForVisit && errors.reasonForVisit ? <span className="errorMessage">{errors.reasonForVisit}</span> : <></>}
          </div>
          <label htmlFor="reasonForVisit">
            <select id="reasonForVisit" onChange={(e) => handleChange(e)} value={getFieldProps('reasonForVisit').value}>
              {reasonsListRender}
            </select>
          </label>
        </div>

        {formik.values.reasonForVisit === 'other' ? (
          <div className="form-field" id="other-inputField-container">
            <label htmlFor="otherDescription" id="other-label">
              <div className="inputLabels">
                <p className="field" id="acct-validator-reasons-other">
                  Other
                </p>
                {touched.otherDescription && errors.otherDescription ? <span className="errorMessage">{errors.otherDescription}</span> : <></>}
              </div>
              <input
                id="otherDescription"
                type="text"
                {...getFieldProps('otherDescription')}
                value={getFieldProps('otherDescription').value}
                onChange={(e) => handleChange(e)}
              />
            </label>
          </div>
        ) : (
          <></>
        )}

        <div className="button-container" id="continue-button-container">
          <button id="continue-button" type="submit" disabled={isSubmitting}>
            Continue
          </button>
        </div>
      </form>
    </>
  );
};
